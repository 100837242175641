<template>
  <div>
    <b-row class="no-gutters px-3 px-sm-0">
      <b-col md="8">
        <h1 class="mr-sm-4 header-tablepage">Setting</h1>
      </b-col>
    </b-row>
    <b-container class="no-gutters bg-white mt-3">
      <b-row>
        <b-col
          v-for="items of menu"
          cols="6"
          class="pointer my-3"
          @click.prevent="$router.push(items.path)"
          :key="items.id"
        >
          <div class="bg-icon shadow-sm">
            <font-awesome-icon :icon="items.icon" size="lg" />
          </div>
          <div class="content">
            <div class="ft-weight" style="text-transform: uppercase">
              {{ items.title }}
            </div>
            <div class="ft-10">{{ items.desc }}</div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {
          id: 0,
          title: "MemberShip",
          desc: "การตั้งค่ารหัสสมาชิก, ระดับสมาชิก , Member Prefix ID",
          path: "/setting/membership",
          icon: "copy",
        },
        {
          id: 1,
          title: "line setting",
          desc: "การตั้งค่าข้อเสนอและนโยบายของระบบ",
          path: "/setting/line",
          icon: "lock",
        },
        {
          id: 30,
          title: "Language",
          desc: "การตั้งค่า Language",
          path: "/setting/Language",
          icon: "language",
        },
        {
          id: 2,
          title: "theme",
          desc: "การปรับรูปแบบอัตลักษณ์, สี, รูปแบบอักษร, ทั้งหมดของระบบ",
          path: "/setting/theme",
          icon: "palette",
        },
        {
          id: 28,
          title: "chart theme",
          desc: "การปรับสีของกราฟทั้งหมดในระบบ",
          path: "/setting/chart-theme",
          icon: "chart-bar",
        },
        {
          id: 3,
          title: "Business Information",
          desc: "การตั้งค่าและเก็บข้อมูลสาขา, ข้อมูลที่ตั้งสาขา, พนักงานในร้าน",
          path: "/setting/bussinessinfo",
          icon: "info-circle",
        },

        {
          id: 4,
          title: "Pdpa email template",
          desc: "การตั้งค่าระบบอีเมล สำหรับรองรับการเข้าถึงข้อมูลส่วนบุคคล",
          path: "/setting/pdpasetting",
          icon: "file-invoice",
        },

        {
          id: 5,
          title: "E-Commerce",
          desc: "เชื่อมต่อระบบ Marketplace",
          path: "/setting/ecommerce",
          icon: "file-invoice",
        },

        {
          id: 7,
          title: "Personal Customer",
          desc: "ตั้งค่าการลงทะเบียนแบบ Personal Customer (ลูกค้าปกติ)",
          path: "/setting/personal",
          icon: "user-cog",
        },
        // {
        //   id: 26,
        //   title: "Company Customer",
        //   desc: "ตั้งค่าการลงทะเบียนแบบ Company Customer (ลูกค้าแบบบริษัท, ร้านค้า)",
        //   path: "/setting/company",
        //   icon: "user-cog",
        // },
        {
          id: 21,
          title: "Shipping",
          desc: "ตั้งค่าขนส่ง",
          path: "/setting/shipping",
          icon: "truck",
        },
        {
          id: 20,
          title: "Address",
          desc: "ตั้งค่ารายละเอียดที่อยู่",
          path: "/setting/address",
          icon: "address-book",
        },
        // {
        //   id: 24,
        //   title: "SOCIAL MEDIA",
        //   desc: "ตั้งค่ารายละเอียดโซเซียลมีเดีย",
        //   path: "/setting/socialmedia",
        //   icon: "mobile-alt",
        // },
        {
          id: 23,
          title: "Customer Type",
          desc: "ตั้งค่าประเภทลูกค้า",
          path: "/setting/customer-type",
          icon: "mobile-alt",
        },
        // {
        //   id: 22,
        //   title: "Customer Group",
        //   desc: "ตั้งค่ารายละเอียดกลุ่มลูกค้า",
        //   path: "/setting/customer-group",
        //   icon: "users",
        // },
        {
          id: 6,
          title: "Booking",
          desc: "จัดการระบบ Booking",
          path: "/setting/booking",
          icon: "calendar-check",
        },
        {
          id: 8,
          title: "Term and condition",
          desc: "รายละเอียดเงื่อนไขและนโยบาย",
          path: "/update/term",
          icon: "file-invoice",
        },
        {
          id: 9,
          title: "PRIVACY POLICY",
          desc: "รายละเอียดการตั้งค่าความเป็นส่วนตัว",
          path: "/update/policy",
          icon: "file-invoice",
        },

        {
          id: 10,
          title: "Payment Type",
          desc: "รูปแบบการชำระเงิน",
          path: "/setting/payment-type",
          icon: "money-bill",
        },

        {
          id: 11,
          title: "Branch Group",
          desc: "กลุ่มสาขา",
          path: "/setting/branch-group",
          icon: "shopping-bag",
        },
        {
          id: 25,
          title: "Product Custom Field",
          desc: "ตั้งค่าสินค้า",
          path: "/setting/product-field",
          icon: "table",
        },

        {
          id: 12,
          title: "Age Group",
          desc: "กลุ่มอายุ",
          path: "/setting/age-group",
          icon: "registered",
        },
        {
          id: 14,
          title: "Report",
          desc: "ตั้งค่ารายงาน",
          path: "/setting/report",
          icon: "file",
        },
        {
          id: 15,
          title: "OTP LOG",
          desc: "ประวัติการส่ง OTP",
          path: "/setting/otp-log",
          icon: "cog",
        },
        // {
        //   id: 16,
        //   title: "Plugins",
        //   desc: "จัดการปลั๊กอิน",
        //   path: "/setting/plugins",
        //   icon: "puzzle-piece",
        // },

        {
          id: 17,
          title: "Receipt",
          desc: "การตั้งค่าใบเสร็จ",
          path: "/setting/receipt-menu",
          icon: "receipt",
        },
        {
          id: 18,
          title: "Communication Channel Usage",
          desc: "การตั้งค่าการใช้ช่องทางการสื่อสาร",
          path: "/setting/channel-usage",
          icon: "receipt",
        },
        {
          id: 19,
          title: "Ticket",
          desc: "การตั้งค่า Ticket",
          path: "/setting/ticket",
          icon: "receipt",
        },
        {
          id: 31,
          title: "Team",
          desc: "การตั้งค่า Team",
          path: "/setting/team",
          icon: "users",
        },

        {
          id: 27,
          title: "Webhook",
          desc: "การตั้งค่า Webhook",
          path: "/setting/webhook",
          icon: "cog",
        },
        // {
        //   id: 29,
        //   title: "Brand",
        //   desc: "การตั้งค่า Brand",
        //   path: "/setting/brand",
        //   icon: "info-circle",
        // },

        {
          id: 13,
          title: "System",
          desc: "ตั้งค่าทั่วไป",
          path: "/setting/general",
          icon: "cog",
        },
        {
          id: 36,
          title: "Line Rich Menu",
          desc: "การตั้งค่า Rich Menu",
          path: "/setting/rich-menu",
          icon: "table",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-icon {
  background-color: var(--theme-secondary-color);
  width: 50px;
  height: 50px;
  border-radius: 10px;
  color: var(--primary-color);
  padding: 15px;
  text-align: center;
}
.content {
  position: absolute;
  top: 5px;
  margin-left: 60px;
}
.ft-10 {
  font-size: 10px;
}
.ft-weight {
  font-weight: 600;
}
</style>
